<template>
    <ToastViewport class="fixed bottom-0 right-0 m-5 outline-none z-[100]"/>
    <ToastRoot v-for="(toast, idx) of toastStore.toasts" :key="idx" :duration="toast.duration || 4000"
               class="flex gap-3 py-5 px-4 mt-2 rounded shadow-md text-white" :class="{
            'bg-[#48c78e]': toast.severity === 'success',
            'bg-[#ffe08a]': toast.severity === 'warn',
            'bg-[#f14668]': toast.severity === 'error',
            'bg-[#3e8ed0]': toast.severity === 'info'
        }">

        <ToastDescription as-child>
            {{ toast.description }}
        </ToastDescription>
        <ToastAction alt-text="Close notification" as-child>
            <button class="inline-flex items-center justify-center w-6 h-6 rounded-full bg-opacity-50 bg-black">
                <Icon icon='x' size="xs"/>
            </button>
        </ToastAction>
    </ToastRoot>
</template>

<script setup lang="ts">
import {
  ToastAction,
  ToastDescription,
  ToastRoot,
  ToastViewport,
} from 'radix-vue'
import Icon from '~/components/ui/atom/icon.vue';

const toastStore = useToastStore();

</script>