<template>
  <DialogRoot>
    <DialogTrigger as="div">
      <slot name="trigger"></slot>
    </DialogTrigger>
    <DialogPortal>
      <DialogContent
          class="text-gray-700 z-50 fixed bottom-0 right-0 m-5 p-6 max-w-lg overflow-scroll bg-white rounded-md border border-gray-600">
        <DialogTitle class="text-2xl mb-6" v-if="props.title">
          {{ props.title }}
        </DialogTitle>
        <DialogDescription class="text-lg mb-3" v-if="props.description">
          {{ props.description }}
        </DialogDescription>

        <slot></slot>

        <DialogClose
            class="btn-link absolute top-0 right-0 m-6 inline-flex h-[25px] w-[25px] items-center justify-center"
            aria-label="Close">
          <Icon class="text-2xl" icon='times'/>
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>

<script setup lang="ts">
import Icon from '~/components/ui/atom/icon.vue'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue'

interface ModalProps {
  title?: string;
  description?: string;
}

const props = defineProps<ModalProps>();

</script>