<template>
  <!-- Desktop nav links -->
  <ul class="hidden xl:grow xl:flex xl:gap-x-7 2xl:gap-x-9 xl:items-center">
    <NavMenuLink v-for="(navLink, index) in navLinkList.filter(link => link.label != null && link.url != null)"
                 :id="index"
                 :label="navLink.label"
                 :url="navLink.url"
                 :tag="navLink.tag"
                 :tag-text-color="navLink.tagTextColor"
                 :tag-background-color="navLink.tagBackgroundColor" />
  </ul>

  <div class="flex xl:gap-x-5">
    <!-- Desktop Search -->
    <SearchBar class="hidden xl:flex xl:my-auto xl:grow"/>

    <!-- Desktop Activate Test -->
    <NuxtLink class="hidden xl:rounded-md xl:border xl:border-gray-300 xl:focus:text-gold-100 xl:focus:border-gray-600 xl:hover:text-gold-100 xl:hover:border-gray-600 xl:transition-colors xl:duration-200 xl:ease-in-out xl:my-3 xl:px-4 xl:flex xl:items-center xl:text-center"
              to="/activate"
              v-if="isTestingEnabled"
              external >
      Activate Test
    </NuxtLink>

    <!-- Desktop Account Options -->
    <div class="hidden relative rounded-t-md bg-white xl:block"
         :class="{ 'drop-shadow-md': isAccountOptionsMenuActive }"
         v-if="isLoggedIn" >

      <button class="m-3 w-[36px] h-[36px] rounded-full bg-gradient-to-br from-[#e45dbf] to-[#ff9e1c]"
              aria-label="Account options menu"
              @click="toggleAccountOptionsMenu"
              v-click-outside="closeAccountOptionsMenu" >
          <span class="text-white font-bold leading-snug">
              {{ userInitials }}
          </span>
      </button>
      <AccountMenu :account-menu-option-list="accountMenuOptionList"
                   :is-visible="isAccountOptionsMenuActive" />
    </div>
    <NuxtLink class="hidden rounded-md border border-gray-300 focus:text-gold-100 focus:border-gray-600 hover:text-gold-100 hover:border-gray-600 transition-colors duration-200 ease-in-out my-3 px-4 xl:flex xl:items-center"
              to="/login"
              v-else
              external >
      Sign In
    </NuxtLink>

    <!-- Cart button -->
    <NuxtLink class="relative my-auto" to="/cart" aria-label="Go to cart" external>
      <!-- TODO; placeholder icon until we wire up a licensed version of fontawesome -->
      <Icon class="text-2xl" icon='cart-shopping'/>
      <span class="absolute px-1 left-[75%] rounded-sm text-sm bg-gray-900 text-white"
            v-if="!!cartCount">
          {{ cartCount }}
      </span>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import SearchBar from "~/components/ui/molecule/search/searchBar.vue"
import Icon from "~/components/ui/atom/icon.vue"
import {NuxtLink} from "#components"
import AccountMenu, {type AccountOption} from "~/components/ui/molecule/accountMenu.vue"
import NavMenuLink, {type BuilderNavLink} from "~/components/ui/atom/navMenuLink.vue"

interface DesktopNavMenuProps {
  navLinkList?: BuilderNavLink[]
  isLoggedIn?: boolean
  isTestingEnabled?: boolean
  cartCount?: number
  userInitials?: string
  userAuthorities?: string[]
}

const {
  navLinkList = [],
  isLoggedIn = false,
  isTestingEnabled = false,
  cartCount = 0,
  userInitials = '',
  userAuthorities = [],
} = defineProps<DesktopNavMenuProps>();

const isAccountOptionsMenuActive = ref(false);
const toggleAccountOptionsMenu = () => isAccountOptionsMenuActive.value = !isAccountOptionsMenuActive.value;
const closeAccountOptionsMenu = () => isAccountOptionsMenuActive.value = false;
const hasRole = (role: string) => userAuthorities.includes(role)
const accountMenuOptionList: AccountOption[] = [
  {label: "Profile", path: "/account/profile", icon: 'user'},
  {
    label: "Dispensary",
    path: "/account/dispensary",
    icon: 'briefcase-medical',
    show: () => hasRole("ROLE_PROFESSIONAL")
  },
  {label: "Integrations", path: "/account/developer", icon: 'link', show: () => hasRole("ROLE_DEVELOPER")},
  {label: "Storefront", path: "/account/storefront", icon: 'store', show: () => hasRole("ROLE_AMBASSADOR")},
  {label: "Track Order", path: "/account/orders", icon: 'barcode'},
  {label: "Settings", path: "/account/settings/personal", icon: 'cog'},
  {label: "Sign Out", path: "/logout", icon: 'arrow-right-from-bracket'},
]

</script>
