<template>
    <SelectRoot v-model="selectedCountry">
        <SelectTrigger class="flex gap-1 items-center">
            <SelectValue class="flex gap-1 items-center" placeholder="Select your country"/>
            <SelectIcon>
                <Icon icon='chevron-down'/>
            </SelectIcon>
        </SelectTrigger>

        <SelectPortal>
            <SelectContent class=" z-50">
                <SelectViewport class="py-2 rounded-md bg-white drop-shadow-lg">
                    <SelectItem
                        class="p-1 text-sm data-[state=checked]:bg-black data-[state=checked]:text-white focus:bg-gray-300 focus:outline-none"
                        v-for="locale of locales" :value="locale.localeCode">
                        <SelectItemText class="flex gap-1 items-center">
                            <NuxtImg provider="cdnProvider" class="w-4 h-4" aria-hidden="true"
                                     :src="`/images/layout/flags/${locale.country.code.toLowerCase()}.png`"/>
                            {{ locale.country.name }}
                        </SelectItemText>
                    </SelectItem>
                </SelectViewport>
            </SelectContent>
        </SelectPortal>
    </SelectRoot>
</template>

<script setup lang="tsx">
import Icon from "~/components/ui/atom/icon.vue"
import {
  SelectRoot,
    SelectContent,
    SelectIcon,
    SelectItem,
    SelectItemText,
    SelectPortal,
    SelectTrigger,
    SelectValue,
    SelectViewport,
} from 'radix-vue'
import {NuxtImg} from '#components'

const localeStore = useLocaleStore();

// fetch shippable countries
const locales = localeStore.locales;
const selectedCountry = computed({
    get: () => localeStore.locale?.localeCode,
    set: (val) => val && localeStore.setLocale(val)
});

</script>