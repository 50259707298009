<template>
    <nav aria-label="Primary" class="border-b border-gray-300 bg-white">
        <div class="flex container h-16 gap-x-3 xl:h-18 xl:gap-x-9">
            <!-- Tablet/Mobile hamburger menu button -->
            <button class="my-auto xl:hidden" aria-label="Open mobile menu" @click="toggleMobileMenu">
                <!-- TODO; placeholder icons until we wire up a licensed version of fontawesome -->
                <Icon class="text-2xl" :icon="isMobileMenuActive ? 'times' : 'bars'"/>
            </button>

            <!-- Logo -->
            <NuxtLink to="/" class="flex gap-3 m-auto grow xl:grow-0" external>
                <NuxtImg provider="cdnProvider" alt="Thorne logo" src="/images/layout/thorne-logo.svg"
                         class="m-auto h-[22px]"/>
                <NuxtImg provider="cdnProvider" :alt="`${localeStore.locale?.country.code} flag`"
                         :src="`/images/layout/flags/${localeStore.locale?.country.code.toLowerCase()}.png`"
                         class="w-8 h-8 hidden xl:block" v-if="localeStore.locale?.country.code !== 'US'"/>
            </NuxtLink>

            <DesktopNavMenu :nav-link-list="navLinks?.desktop"
                            :cart-count="cartCount"
                            :user-initials="userStore.data?.initials"
                            :user-authorities="userStore.data?.authorities.map((a) => a.authority)"
                            :is-logged-in="userStore.loggedIn"
                            :is-testing-enabled="localeStore.locale?.country.testingEnabled" />
        </div>
        <MobileNavMenu :nav-link-list="navLinks?.mobile"
                       :user-name="userStore.data?.name"
                       :is-logged-in="userStore.loggedIn"
                       v-model:is-visible="isMobileMenuActive" />
    </nav>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Icon from '~/components/ui/atom/icon.vue'
import {NuxtLink} from '#components'
import DesktopNavMenu from "~/components/ui/molecule/desktopNavMenu.vue"
import MobileNavMenu from "~/components/ui/molecule/mobileNavMenu.vue"
import {type BuilderNavLink} from "~/components/ui/atom/navMenuLink.vue"

interface NavLinkData {
    desktop: BuilderNavLink[];
    mobile: BuilderNavLink[];
}

const isMobileMenuActive = ref(false)

// fetch nav links
const model = "navigation-links"
const route = useRoute()
const {data: navLinks} = await useAsyncData(() => builderIoService().fetchOneModel<NavLinkData>(model, route.path), {
    transform: (navLinks) => navLinks.data
});

// fetch stores
const localeStore = useLocaleStore()
const userStore = useUserStore()
const cartStore = useCartStore()

const cartCount = computed(() => cartStore.data?.lines.reduce((qty, line) => qty + line.quantity, 0))

const toggleMobileMenu = () => isMobileMenuActive.value = !isMobileMenuActive.value

</script>