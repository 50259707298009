<template>
    <form class="*:mb-2">
        <h3 class="text-lg">Want to opt into Thorne SMS?</h3>

        <Checkbox label="I want to receive marketing text messages (for example, promotions) at the number provided."
                    v-model="marketingOptIn">
        </Checkbox>

        <Checkbox
            label="I want to receive transactional text messages (for example, order status updates) at the number provided."
            v-model="transactionalOptIn">
        </Checkbox>

        <Input v-model="phoneNumber" label="Mobile number"
                 :error-label-id="errors.phoneNumber ? 'phone-number-error' : undefined" inputmode="tel"
                 :format="{ blocks: [10], numericOnly: true }" :disabled="!optInSelected" floating-label/>
        <div id="phone-number-error" class="text-sm" v-if="errors.phoneNumber">
            {{ errors.phoneNumber }}
        </div>

        <p class="py-2">
            Enter your phone number to sign up for Thorne SMS and begin receiving marketing and/or transactional
            messages. Message and data rates may apply. Message frequency varies. Text HELP to 25816 for help. Text
            STOP to 25816 to cancel. For terms: thorne.com/terms-of-use. For privacy policy: thorne.com/privacy-policy.
        </p>

        <button
            class="w-full rounded-md text-white bg-[#191919] focus:bg-gray-700 hover:bg-gray-700 disabled:bg-gray-300 disabled:text-gray-600 transition-colors duration-200 ease-in-out h-14"
            :disabled="isSubmitting || !optInSelected" @click.prevent="submitSmsSignup">
            Submit
        </button>
    </form>
</template>

<script setup lang="ts">
import {useForm} from 'vee-validate'
import {z} from 'zod'
import {toTypedSchema} from '@vee-validate/zod'
import debounce from 'debounce'
import {useSecurityStore} from '~/stores/securityStore'
import Checkbox from '~/components/ui/atom/checkbox.vue'
import Input from '~/components/ui/atom/input.vue'

const emit = defineEmits(['onSuccess', 'onError']);
const marketingOptIn = ref(false);
const transactionalOptIn = ref(false);

const validationSchema = toTypedSchema(
    z.object({
        phoneNumber: z.string()
    })
);

const {errors, isSubmitting, handleSubmit, defineField} = useForm({validationSchema});
const [phoneNumber] = defineField('phoneNumber');

const optInSelected = computed(() => marketingOptIn.value || transactionalOptIn.value);

const securityStore = useSecurityStore();
const submitSmsSignup = handleSubmit(debounce((data) => {
    if (!securityStore.data?.csrf) {
        console.error("Invalid CSRF token/data.");
        return;
    }

    $fetch('/sms/subscribe', {
        method: 'POST',
        headers: {
            [securityStore.data.csrf.headerName]: securityStore.data.csrf.token
        },
        body: {
            phoneNumber: data.phoneNumber,
            marketingTexts: marketingOptIn.value,
            transactionalTexts: transactionalOptIn.value
        }
    })
        .then(res => emit('onSuccess', res))
        .catch(err => emit('onError', err));
}, 500, {immediate: true}));

</script>