<template>
    <div class="px-3 bg-white border border-gray-300 rounded-md" :class="{ 'bg-gray-400 border-gray-500': disabled }">
        <div class="relative flex items-center" :class="{ 'pt-2': floatingLabel && inputPopulated }">
            <input :id="`${inputId}-input`" v-model="value" :disabled="disabled" :inputmode="props.inputmode"
                :aria-errormessage="inputPopulated ? props.errorLabelId : undefined"
                class="w-full h-12 focus:outline-none disabled:bg-transparent" />

            <label :for="`${inputId}-input`" class="text-gray-600 absolute transition-all left-0" :class="{
                'top-1/4': !floatingLabel || !inputPopulated,
                'hidden': !floatingLabel && inputPopulated,
                'text-xs top-1': floatingLabel && inputPopulated }">

                {{ props.label }}
            </label>
        </div>
    </div>
</template>

<script setup lang="ts">

import { formatGeneral, type FormatGeneralOptions } from 'cleave-zen';

interface InputProps {
    inputmode: "search" | "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal";
    label: string;
    errorLabelId?: string;
    disabled?: boolean;
    floatingLabel?: boolean;
    format?: FormatGeneralOptions;
}

const inputId = useId();
const value = defineModel<string>();
const inputPopulated = computed(() => value.value != null && value.value.toString().length > 0);

const props = withDefaults(defineProps<InputProps>(), {});

watch(value, (val) => {
    if (val != null && props.format != null) {
        value.value = formatGeneral(val, props.format);
    }
})

</script>