<template>
    <footer>
        <!-- Locale exclusivity banner -->
        <div class="py-7 border-t border-t-gray-300 bg-[#fdf3e6]" v-if="localeStore.locale?.country.code !== 'US'">
            <div class="container flex gap-5 justify-center items-center">
                <NuxtImg provider="cdnProvider" class="w-8 h-8" aria-hidden="true"
                         :src="`/images/layout/flags/${localeStore.locale?.country.code.toLowerCase()}.png`"/>
                <span class="font-medium text-lg">This site is exclusive to Thorne Retail customers.</span>
            </div>
        </div>

        <div class="border-t border-t-gray-300">
            <div class="container flex flex-col sm:flex-row">
                <!-- Logo & Locale -->
                <div class="grow basis-0 py-9">
                    <NuxtImg provider="cdnProvider" alt="Thorne logo" src="/images/layout/t.svg"
                             class="w-[49px] h-[49px] mb-10"/>

                    <LocaleSelect/>
                </div>

                <!-- Links/Columns -->
                <div class="grow py-9 grid grid-cols-2 gap-y-20 sm:gap-y-0 sm:grid-cols-4"
                     v-if="!!footerData?.footerLinks">
                    <div v-for="footerLinks of footerData.footerLinks">
                        <h3 class="text-lg font-medium">{{ footerLinks.header }}</h3>
                        <ul class="mt-4">
                            <template v-for="link of footerLinks.links">
                                <li class="mb-1" v-if="hasFlags(link.restrictionFlags)">
                                    <NuxtLink :to="link.url" external>
                                        {{ link.label }}
                                    </NuxtLink>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>

                <!-- Side options -->
                <div class="flex flex-col gap-3 py-9 px-6 text-center sm:text-left sm:border-l sm:border-l-gray-300">
                    <!-- Help/Contact links-->
                    <div v-if="!!footerData?.contactLinks">
                        <h3 class="text-lg font-medium">Need help</h3>
                        <ul class="mt-4">
                            <li class="mb-1" v-for="footerLinks of footerData.contactLinks">
                                <NuxtLink :to="footerLinks.url" external>
                                    {{ footerLinks.label }}
                                </NuxtLink>
                            </li>
                        </ul>
                    </div>

                    <!-- App/Social links-->
                    <div>
                        <Modal v-if="userStore.loggedIn" :title="hasSubmitSms ? 'Success!' : 'Thorne SMS'"
                                 :description="hasSubmitSms ? 'Thank you for opting into Thorne SMS.' : 'Sign up for text messages from Thorne for back in stock notifications, shipping updates, and more.'">
                            <template #trigger>
                                <button class="btn-link font-bold underline mb-2">Sign up for SMS</button>
                            </template>

                            <SmsOptInForm @on-success="hasSubmitSms = true"
                                                  @on-error="toastStore.publish('Unable to submit SMS.', 'error')"
                                                  v-if="!hasSubmitSms"/>
                        </Modal>

                        <div v-for="appLink of footerData?.mobileAppLinks">
                            <NuxtLink class="font-bold underline" :to="appLink.url" external>
                                Download our {{ appLink.platform }} app
                            </NuxtLink>
                        </div>

                        <ul class="flex flex-row py-3">
                            <li class="grow" v-for="socialLink of footerData?.socialMediaLinks">
                                <NuxtLink class="grow" :aria-label="`Go to ${socialLink.name} profile`"
                                          :to="socialLink.url" external>
                                    <Icon class="font" :icon="socialLink.icon" size="lg"/>
                                </NuxtLink>
                            </li>
                        </ul>
                    </div>

                    <!-- Email signup -->
                    <div class="flex flex-col gap-1">
                        <div id="footer-email-heading" class="contents" v-if="!!footerData?.emailListing">
                            <h3 class="font-bold" v-if="!!footerData.emailListing.title">
                                {{ footerData.emailListing.title }}
                            </h3>
                            <p v-if="!!footerData.emailListing.subtitle">
                                {{ footerData.emailListing.subtitle }}
                            </p>
                        </div>
                        <EmailSubscriptionForm class="my-3" :list-names="['footer-opt-ins']"
                                                       aria-labelledby="footer-email-heading"
                                                       @on-success="toastStore.publish('Thank you! Keep an eye on your inbox!', 'success')"
                                                       @on-error="toastStore.publish('Unable to submit email', 'error')"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Copyright, TOS, Privacy, etc resources -->
        <div class="border-y border-y-gray-300">
            <div class="container py-6 flex flex-wrap gap-6 justify-center">
                <h3>&copy; Thorne. All rights reserved.</h3>
                <ul class="contents">
                    <li v-for="policyLink of footerData.policyLinks" v-if="!!footerData?.policyLinks">
                        <NuxtLink class="my-auto" :to="policyLink.url" external>
                            {{ policyLink.label }}
                        </NuxtLink>
                    </li>
                    <!-- TrueVault Privacy Links; Client only because the polaris script directly manipulates the dom and causes hydration mismatch issues -->
                    <ClientOnly>
                        <li class="truevault-polaris-privacy-notice" hidden>
                            <a class="my-auto"
                               href="https://privacy.thorne.com/privacy-policy#california-privacy-notice">
                                California Privacy Notice
                            </a>
                        </li>
                        <li class="truevault-polaris-privacy-notice" hidden>
                            <a class="my-auto" href="https://privacy.thorne.com/limit">
                                Sensitive Personal Information
                            </a>
                        </li>
                        <li class="truevault-polaris-optout" hidden>
                            <a class="my-auto" href="https://privacy.thorne.com/opt-out">
                                <img src="https://polaris.truevaultcdn.com/static/assets/icons/optout-icon-black.svg"
                                     alt="California Consumer Privacy Act (CCPA) Opt-Out Icon"
                                     class="align-middle h-[14px]"/>
                                Your Privacy Choices
                            </a>
                        </li>
                    </ClientOnly>
                </ul>
            </div>
        </div>

        <!-- Dshea -->
        <div class="container">
            <div class="border border-black text-center m-4 p-4">
                * These statements have not been evaluated by the Food
                and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.
            </div>
        </div>
    </footer>
</template>

<script setup lang="tsx">
import Icon from '~/components/ui/atom/icon.vue';
import LocaleSelect from '~/components/ui/molecule/localeSelect.vue'
import Modal from '~/components/ui/atom/modal.vue'
import EmailSubscriptionForm from '~/components/ui/organism/emailSubscriptionForm.vue'
import SmsOptInForm from '~/components/ui/organism/smsOptInForm.vue'
import {NuxtLink} from '#components'

interface FooterLinks {
    header: string;
    links: {
        label: string;
        url: string;
        restrictionFlags: string[]
    }[];
}

interface ContactLink {
    label: string;
    url: string;
}

interface PolicyLink {
    label: string;
    url: string;
}

interface SocialLink {
    name: string;
    url: string;
    icon: string;
}

interface AppLink {
    platform: string;
    url: string;
}

interface EmailListing {
    title: string;
    subtitle: string;
}

interface FooterData {
    footerLinks: { column: BuilderIOEnrichedData<FooterLinks> }[];
    socialMediaLinks: { socialMediaLink: BuilderIOEnrichedData<SocialLink> }[];
    mobileAppLinks: { mobileAppLink: BuilderIOEnrichedData<AppLink> }[];
    contactLinks: { contactLink: BuilderIOEnrichedData<ContactLink> }[];
    policyLinks: { policyLink: BuilderIOEnrichedData<PolicyLink> }[];
    emailListing: EmailListing;
}

// fetch footer data
const route = useRoute();
const {data: footerData} = await useAsyncData(() => builderIoService().fetchOneModel<FooterData>("footer-data", route.path, true), {
    transform: (data: BuilderIOData<FooterData>) => {
        // unwrap all enriched data from the model so we don't have to drill into it within the template...
        return {
            footerLinks: data.data?.footerLinks.map(fl => fl.column.value.data),
            socialMediaLinks: data.data?.socialMediaLinks.map(fl => fl.socialMediaLink.value.data),
            mobileAppLinks: data.data?.mobileAppLinks.map(fl => fl.mobileAppLink.value.data),
            contactLinks: data.data?.contactLinks.map(fl => fl.contactLink.value.data),
            policyLinks: data.data?.policyLinks.map(fl => fl.policyLink.value.data),
            emailListing: data.data?.emailListing
        };
    }
});

const userStore = useUserStore();
const localeStore = useLocaleStore();
const toastStore = useToastStore();
const hasSubmitSms = ref(false);

const hasFlags = (requiredFlags?: string[]) => {
    if (requiredFlags == null || requiredFlags.length <= 0) return true; // no required flags

    if (localeStore.locale?.country != null) {
        for (const flag of requiredFlags) {
            if (!hasFlag(localeStore.locale.country, flag)) {
                return false;
            }
        }
    }

    // has all flags, return true
    return true;
}

const hasFlag = (country: Country, requiredFlag: string) => {
    switch (requiredFlag) {
        case "COUNTRY_PROFESSION_ENABLED":
            return country.professionEnabled;
        case "COUNTRY_TESTING_ENABLED":
            return country.testingEnabled;
        case "COUNTRY_SHIPPING_ENABLED":
            return country.shippingEnabled;
        default:
            return false;
    }
}
</script>