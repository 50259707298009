<template>
    <div class="relative select-none" :class="{ 'flex items-center gap-4' : !!props.label }">
        <input type="checkbox" :id="cboxId" v-model="checked"
               class="cursor-pointer shrink-0 appearance-none w-[1em] h-[1em] border border-gray-900 checked:bg-gray-900"/>
        <Icon class="absolute pointer-events-none text-white" width="1em" height="1em" icon='check' v-if="checked"/>
        <label class="text-sm" :for="cboxId" v-if="props.label">
            {{ props.label }}
        </label>
    </div>
</template>

<script setup lang="ts">
import Icon from '~/components/ui/atom/icon.vue';

interface CheckboxProps {
    label?: string;
}

const cboxId = useId();
const props = defineProps<CheckboxProps>();
const checked = defineModel<boolean>();

</script>