<template>
    <form role="search"
          @submit.prevent="navigateTo(`/search?q=${searchQuery}`, {'external': true})"
          class="group relative flex gap-3 content-center rounded-full border p-2 border-gray-300 hover:border-gray-600 focus-within:border-gray-800"
          v-click-outside="clearSearch">

        <Icon class="my-auto text-gray-500 group-focus-within:text-gray-700" icon='search'/>

        <input type="search" class="grow my-auto focus:outline-none bg-white" :placeholder="props.placeholder || 'Search'"
               aria-label="Search for content on Thorne" v-model="searchQuery" @input="onSearchInputChanged"/>

        <div role="menu" class="flex flex-col absolute top-[102%] left-0 w-full p-3 drop-shadow-md rounded-md bg-white"
             v-if="searchResults.length > 0">
            <search-result-category :query=searchQuery :search-suggestions=searchResults></search-result-category>
        </div>
        <div class="sr-only" id="results-suggestions-status" role="status">
            {{ accessibilitySearchResultsAvailable }}
        </div>
    </form>
</template>

<script setup lang="tsx">
import Icon from '~/components/ui/atom/icon.vue';
import debounce from 'debounce';
import {navigateTo} from '#app';
import SearchResultCategory from "~/components/ui/molecule/search/searchResultCategory.vue";

interface SearchResults {
    q: string
    recommendationContext: {
        type: string
        data: string[]
    }
    searchPaging: {}
    filters: {}
    page: SearchResultsPage;
}

interface SearchResultsPage {
    products?: {}
    articles?: {}
    videos?: {}
    resources?: {}
    ingredients?: {}
    podcasts?: {}
    affiliates?: {}
    metaTitle: string;
    resultsAvailableFor: SearchResultCategories[];
}

enum SearchResultCategories {
    Products = 'Products',
    Articles = 'Articles',
    Videos = 'Videos',
    Resources = 'Resources',
    Ingredients = 'Ingredients',
    Podcasts = 'Podcast',
    Affiliates = 'Affiliates'
}

interface SearchBarProps {
    placeholder?: string;
}

const props = defineProps<SearchBarProps>();

const searchQuery = ref<string>("");
const searchResults = ref<string[]>([]);
const accessibilitySearchResultsAvailable = computed(() => searchResults.value.length > 0 ? "Suggestions available" : '')

const onSearchInputChanged = debounce((e: Event) => {
    if (!(e.target instanceof HTMLInputElement)) return;

    const target: HTMLInputElement = e.target;
    const value: string = target.value;

    if (value != null && value.length > 0) {
        searchQuery.value = value
        fetchSearchResults(value);
    }
}, 500);

const fetchSearchResults = (query: string) => {
    searchResults.value = [];
    if (query.length <= 0) return;

    $fetch<SearchResults>('/search', {
        params: {
            q: query,
            format: "json",
        }
    }).then((results: SearchResults) => {
        if (results) {
            searchResults.value = results.page.resultsAvailableFor;
        }
    }).catch((err) => {
        console.error(err);
    });
}

const clearSearch = () => {
    searchQuery.value = "";
    searchResults.value = [];
}

</script>