<template>
    <form>
        <div class="flex gap-1 p-2 border border-gray-300 rounded-md mb-3">
            <input aria-label="Enter your email address" placeholder="Email Address" type="email" class="w-full"
                v-model="email" :bind="emailAttrs" :disabled="isSubmitting" />

            <button aria-label="Submit email form"
                class="bg-black text-white rounded-md py-2 px-4 transition-colors ease-in duration-100 focus:bg-gray-700 hover:bg-gray-700"
                @click="submitEmailSignup" :disabled="isSubmitting">

                <Icon icon='paper-plane'/>
            </button>
        </div>
        <div class="rounded-md bg-gray-300 text-center p-1" v-if="errors.email">{{ errors.email }}</div>
    </form>
</template>

<script setup lang="ts">

import {useForm} from 'vee-validate'
import {z} from 'zod'
import {toTypedSchema} from '@vee-validate/zod'
import Icon from '~/components/ui/atom/icon.vue'
import debounce from 'debounce'

interface EmailSubscriptionFormProps {
    listNames?: string[],
    messageNames?: string[],
    marketingChannel?: boolean
}

const emit = defineEmits(['onSuccess', 'onError']);
const props = defineProps<EmailSubscriptionFormProps>();

// validation
const invalidEmailMessage = "Invalid Email Address";
const validationSchema = toTypedSchema(
    z.object({
        email: z.string({message: invalidEmailMessage}).email(invalidEmailMessage)
    })
);

// define fields using our validation schema
const {errors, isSubmitting, handleSubmit, defineField} = useForm({validationSchema});
const [email, emailAttrs] = defineField('email');

const securityStore = useSecurityStore();
const submitEmailSignup = handleSubmit(debounce(values => {
    if (!securityStore.data?.csrf) {
        console.error("Invalid CSRF token/data.");
        return;
    }

    $fetch('/email/multi/subscribe', {
        method: 'POST',
        headers: {
            [securityStore.data.csrf.headerName]: securityStore.data.csrf.token
        },
        body: {
            lists: props.listNames,
            messages: props.messageNames,
            marketingChannel: props.marketingChannel,
            email: values.email
        }
    })
        .then(res => emit('onSuccess', res))
        .catch(err => emit('onError', err));
}, 500, { immediate: true }));

</script>