<template>
  <div class="container bg-white py-3 xl:hidden">
    <Transition enter-from-class="h-0" enter-to-class="h-screen"
                enter-active-class="transition-[height] ease-linear duration-100">

      <!-- Tablet/Mobile Nav Menu -->
      <div v-if="isVisible" class="container bg-white">
        <NuxtLink to="/cart"
                  class="rounded-md text-white bg-[#191919] focus:bg-gray-700 hover:bg-gray-700 disabled:bg-gray-300 disabled:text-gray-600 transition-colors duration-200 ease-in-out flex items-center justify-center my-4 h-11"
                  external >
          Checkout
        </NuxtLink>

        <SearchBar class="h-12"/>

        <ul class="flex flex-col gap-y-6 py-5 xl:items-center">
          <NavMenuLink v-for="(navLink, index) in navLinkList"
                       :id="index"
                       :label="navLink.label"
                       :url="navLink.url"
                       :tag="navLink.tag"
                       :tag-text-color="navLink.tagTextColor"
                       :tag-background-color="navLink.tagBackgroundColor"
                       ref="mobileNavLinks"/>
        </ul>

        <!-- Table/moble account Options -->
        <div class="flex flex-col gap-y-6 py-5 border-t border-gray-300" v-if="isLoggedIn">
          <span>{{ userName }}</span>
          <NuxtLink to="/account/settings/personal" external>
            Account Settings
          </NuxtLink>
          <NuxtLink to="/logout"
                    external>
            Sign Out
          </NuxtLink>
        </div>
        <NuxtLink to="/login"
                  v-else
                  external >
          Sign In
        </NuxtLink>
      </div>
    </Transition>

    <!-- Tablet/Mobile Nav Search -->
    <SearchBar class="h-10" placeholder="Search products, articles, and more" v-if="!isVisible"/>
  </div>
</template>
<script setup lang="ts">
import { useTemplateRef, onMounted } from 'vue'
import SearchBar from "~/components/ui/molecule/search/searchBar.vue";
import {NuxtLink} from "#components";
import NavMenuLink, {type BuilderNavLink} from "~/components/ui/atom/navMenuLink.vue";
import { useEventListener } from '@vueuse/core'

interface MobileNavMenuProps {
  navLinkList?: BuilderNavLink[]
  isLoggedIn?: boolean
  userName?: string
}

const isVisible = defineModel('isVisible')
const {
  navLinkList = [],
  isLoggedIn = false,
  userName = '',
} = defineProps<MobileNavMenuProps>();

const mobileNavLinkRefs = useTemplateRef<EventTarget[] | null>('mobileNavLinks')

onMounted(()=>{
  const onLinkClicked = () => isVisible.value = false

  mobileNavLinkRefs?.value?.map((element) => {
    useEventListener(element, 'click', onLinkClicked)
  })
})

</script>
