<template>
    <div class="suggestions">
        <ul class="divide-y">
            <li v-for="result in props.searchSuggestions">
                <NuxtLink role="menuitem"
                          class="flex h4 text-left py-1 px-2.5 rounded-md hover:bg-gray-300 hover:text-gray-700"
                          :to="`/search?q=${props.query}#${result.toLowerCase()}`"
                          :aria-label="`View results for ${result}`"
                          external>
                    <Icon class="w4 h4 py-2 mr-4" :icon="`${getCategoryIconClass(result)}`"/>
                    <div class="py-1">
                        {{ result }}
                    </div>
                </NuxtLink>
            </li>
        </ul>
    </div>
</template>

<script setup lang="tsx">
import Icon from '~/components/ui/atom/icon.vue'
import {NuxtLink} from '#components'

interface SearchSuggestionCategoryProps {
    query: string
    searchSuggestions: string[]
}

const props = defineProps<SearchSuggestionCategoryProps>();

function getCategoryIconClass(categoryName: string) {
    switch (categoryName) {
        case "Products":
            return "capsules"
        case "Articles":
            return "newspaper"
        case "Videos":
            return "video"
        case "Ingredients":
            return "mortar-pestle"
        case "Resources":
        case "Affiliates":
        case "Podcasts":
        default:
            return "book"
    }
}
</script>