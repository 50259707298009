<template>
  <Teleport to="#teleports">
    <!-- Salesforce Chat   -->
  </Teleport>
</template>

<script setup lang="ts">
declare const window: any
import {onBeforeMount, onMounted, onUnmounted} from 'vue'
import {UAParser} from 'ua-parser-js'
import waitForGlobalValue from '~/components/utils/waitForGlobalValue'
const config = useRuntimeConfig()

const salesforceMySiteUrl = computed<string>(() => {
  const domain = config.public.salesforceMySiteDomain ?? 'https://thorne.my.site.com'
  const accountID = config.public.salesforceAccountID ?? 'ESWThorneservicebot1726063950510'
  return domain+'/'+accountID
})
const chatScriptAssetURL = computed<string>(() => {
  const jsFileRoute = config.nitro?.envPrefix === 'PROD_' ? '/assets/js/bootstrap.min.js' : '/assets/js/bootstrap.js'
  return salesforceMySiteUrl.value+jsFileRoute
})

async function waitForEmbeddedServiceBootstrap() {
  const bootstrap = await waitForGlobalValue('embeddedservice_bootstrap');
  await initEmbeddedMessaging(bootstrap)
}

async function initEmbeddedMessaging(_) {
  try {
    window.embeddedservice_bootstrap.settings.language = 'en_US'
    window.embeddedservice_bootstrap.settings.shouldShowParticipantChgEvntInConvHist = false
    window.embeddedservice_bootstrap.init(
        config.public.salesforceCode,
        config.public.salesforceName,
        salesforceMySiteUrl.value,
        {
          scrt2URL: config.public.salesforceMySalesforceScrtUrl
        }
    );
  } catch (err) {
    console.error('Error loading Embedded Messaging: ', err)
  }
}
function embeddedMessagingReady(_) {
  let parser = new UAParser(window.navigator.userAgent)
  let parserResults = parser.getResult()
  // Send data to Salesforce
  const fields = {
    'TypePage_URL': window.location.pathname,
    'Browser': `${parserResults.browser.name ?? 'unknown'}|${parserResults.browser.version ?? 'unknown'}`,
    'Device': `${parserResults.device.vendor ?? 'unknown'}|${parserResults.device.model ?? 'unknown'}|${parserResults.device.type ?? 'unknown'}`,
    'Location': ''
  }
  console.log('fields: ', fields)
  window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(fields)
}
function embeddedMessagingInitError(e) {
  console.log('CS Chat Setup Failed', e)
}
function embeddedMessagingSetupSuccessful(e) {
  console.log('CS Chat Setup Successful', e)
}
function embeddedMessagingButtonCreated(e) {
  console.log('CS Chat Button Created', e)
}

onBeforeMount(()=>{
  const sfChatVendorScript = document.createElement("script")

  sfChatVendorScript.setAttribute('type', 'text/javascript')
  sfChatVendorScript.setAttribute('src', chatScriptAssetURL.value)
  sfChatVendorScript.setAttribute('async', '')
  document.body.appendChild(sfChatVendorScript);
})

onMounted(() => {
  waitForEmbeddedServiceBootstrap()
  window.addEventListener('ESW_APP_INITIALIZATION_ERROR', (e)=>{console.log('We ESW INIT ERROR', e)})
  window.addEventListener('onEmbeddedMessagingReady', embeddedMessagingReady)
  window.addEventListener('onEmbeddedMessagingInitError', embeddedMessagingInitError)
  window.addEventListener('onEmbeddedMessagingInitSuccess', embeddedMessagingSetupSuccessful)
  window.addEventListener('onEmbeddedMessagingButtonCreated', embeddedMessagingButtonCreated)
})

onUnmounted( () => {
  window.removeEventListener('onEmbeddedMessagingReady', embeddedMessagingReady)
  window.removeEventListener('onEmbeddedMessagingInitError', embeddedMessagingInitError)
  window.removeEventListener('onEmbeddedMessagingInitSuccess', embeddedMessagingSetupSuccessful)
  window.removeEventListener('onEmbeddedMessagingButtonCreated', embeddedMessagingButtonCreated)
})

</script>