<template>
    <DialogRoot :open="shouldShowEmailPopup" :modal="isMobileBreakpoint" @update:open="onModalStateChanged">
        <DialogPortal>
            <DialogOverlay class="bg-black bg-opacity-50 z-50 fixed inset-0 sm:hidden"></DialogOverlay>
            <DialogContent
                class="text-gray-700 bg-white rounded-md border border-gray-600 z-50 p-7 overflow-scroll fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[325px] w-11/12 max-h-svh sm:bottom-8 sm:right-8 sm:left-[revert] sm:top-[revert] sm:transform-none sm:w-1/4"
                @interact-outside="event => event.preventDefault()">
                <DialogTitle class="text-2xl font-medium">
                    {{ title }}
                </DialogTitle>

                <DialogClose
                    class="btn-link absolute top-7 right-7 inline-flex h-[25px] w-[25px] items-center justify-center"
                    aria-label="Close">
                    <Icon class="text-2xl" icon="times" aria-hidden />
                </DialogClose>

                <div class="pt-6">
                    <template v-if="!optedIntoEmail">
                        <NuxtImg provider="cdnProvider" alt="" src="/images/pages/home/hand_with_shaker.jpg"
                            class="rounded-sm border border-[#EBEBEB] w-full" />

                        <p class="text-xl my-4">
                            Exclusive promotions, personalized
                            product recommendations, wellness content from our experts and more, all
                            delivered to your inbox.
                        </p>

                        <EmailSubscriptionForm :marketing-channel="true" :list-names="['take-5-daily-popover']"
                            :message-names="['loyalty', 'surveys', 'marketing-messages', 'product-promotions', 'company-news', 'take-five']"
                            @on-success="onSubmitEmail" @on-error="onFailToSubmit" />
                    </template>
                    <template v-else-if="!optedIntoSms">
                        <p>Keep an eye on your inbox!</p>
                        <SmsOptInForm @on-success="onSubmitSms" @on-error="onFailToSubmit" />
                    </template>
                    <template v-else>
                        <p>Thank you for opting into Thorne SMS.</p>
                    </template>
                </div>
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
</template>

<script setup lang="ts">

import Icon from '~/components/ui/atom/icon.vue';
import EmailSubscriptionForm from '~/components/ui/organism/emailSubscriptionForm.vue';
import SmsOptInForm from '~/components/ui/organism/smsOptInForm.vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import {
    DialogClose,
    DialogContent,
    DialogPortal,
    DialogRoot,
    DialogTitle,
    DialogOverlay
} from 'radix-vue';

const userStore = useUserStore();
const toastStore = useToastStore();
const hideEmailPopupCookie = useCookie('hide-email-popup');
const breakPoints = useBreakpoints(breakpointsTailwind);
const isMobileBreakpoint = breakPoints.smaller("md");

const optedIntoEmail = ref(false);
const optedIntoSms = ref(false);

const shouldShowEmailPopup = computed(() => {
    return !userStore.loggedIn && !hideEmailPopupCookie.value;
});

const title = computed(() => {
    if (!optedIntoEmail.value) {
        return "Sign up for Thorne Emails";
    }
    else if (!optedIntoSms.value) {
        return "Thank you!";
    }
    else {
        return "Success";
    }
});

const onSubmitEmail = () => {
    optedIntoEmail.value = true;
}

const onSubmitSms = () => {
    optedIntoSms.value = true;
}

const onFailToSubmit = () => {
    toastStore.publish('Unable to submit, please try again.', 'error');
}

const onModalStateChanged = (opened) => {
    if (!opened) {
        hideEmailPopupCookie.value = "1";
    }
}

</script>