<template>
    <div v-if="!!announcementBannerData" class="text-center p-4"
        :style="getAnnouncementBackgroundStyling(announcementBannerData.backgroundColor)">

        <div class="contents" v-html="announcementBannerData.message"></div>
    </div>
</template>

<script setup lang="ts">
import DOMPurify from 'isomorphic-dompurify';

interface AnnouncementBannerData {
    message: string;
    backgroundColor: string;
}

// fetch announcement banner
const model = "announcement-banner";
const route = useRoute();
const { data: announcementBannerData } = await useAsyncData(
    () => builderIoService().fetchOneModel<AnnouncementBannerData>(model, route.path),
    {
        transform: (data : BuilderIOData<AnnouncementBannerData>) => {
            // sanitize the rich-text html from builder
            if (!!data.data) {
                data.data.message = DOMPurify.sanitize(
                    data.data?.message,
                    {
                        ALLOWED_TAGS: ['a', 'u', 'p', 'b', 'i', 'em', 'strong', 'span'],
                        ALLOWED_ATTR: ['href', 'target', 'style', 'rel'],
                    }
                );
            }
            
            return data.data; // strip BuilderIOData wrapping object (just return the announcement banner data)
        }
    });

// can't use dynamic classes with tailwind, have to use styling
const getAnnouncementBackgroundStyling = (color) => {
    return { backgroundColor: color || 'none' };
}

</script>