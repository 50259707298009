export default function waitForGlobalValue(key: string, interval = 100) {
    return new Promise(resolve => {
        const check = () => {
            if (typeof window[key] !== 'undefined') {
                resolve(window[key])
            } else {
                setTimeout(check, interval)
            }
        };
        check()
    })
}