<template>
    <li class="h-fit">
      <NuxtLink v-if="url" :id="'nav-link-'+id" class="flex gap-x-1 items-center" :to="url" external>
        {{label}}
        <div v-if="tag" class="rounded-full text-sm text-center px-3 py-1 text-white"
             :style="getLinkTagStyling">
          {{tag}}
        </div>
      </NuxtLink>
    </li>
</template>

<script setup lang="ts">

import {NuxtLink} from "#components";


export interface BuilderNavLink {
  id: number
  label: string
  url: string
  tag?: string
  tagTextColor?: string
  tagBackgroundColor?: string
}

const {
  id,
  label,
  url,
  tag = '',
  tagTextColor = 'white',
  tagBackgroundColor = 'none',
} = defineProps<BuilderNavLink>();

// can't use dynamic classes with tailwind, have to use styling
const getLinkTagStyling = computed(() => {
  return {color: tagTextColor || 'white', backgroundColor: tagBackgroundColor || 'none'};
})

</script>
